.dark {
	--vkws_landing--section-background--color: var(--vkws_landings--color--black);
	--vkws_landing--title--color: var(--vkws_landings--color--white);
	--vkws_landing--desktop-slider-background--color: var(--vkws_landings--color--jet-black);
	--vkws_landing--desktop-slider-description--color: var(--vkws_landings--color--white);
	--vkws_landing--desktop-slider-icon--color: var(--vkws_landings--color--main_blue);
	--vkws_landing--desktop-slider-green-icon--color: var(--vkws_landings--color--turquoise);
	--vkws_landing--desktop-slider-menu-background-active--color: var(--vkws_landings--color--jet-black);
	--vkws_landing--desktop-slider-menu-background-active--border: var(--vkws_landings--desktop-slider-menu-background-dark-active--border);
	--vkws_landing--desktop-slider-menu-text--color: var(--vkws_landings--color--slate);
	--vkws_landing--desktop-slider-menu-icon--color: var(--vkws_landings--color--turquoise);
	--vkws_landing--slider-item-desc: var(--vkws_landings--color--instruments-card-desc-grey);
	--vkws_landing--desktop-slider-menu-background--color: var(--vkws_landings--color--jet-black);
	--vkws_landing--desktop-slider-content-background--color: var(--vkws_landings--color--dark-grey);
}

.root-full {
	background-color: var(--vkws_landing--section-background--color);
}

.root {
	padding-top: 60px;
	padding-bottom: 60px;
}

.title {
	color: var(--vkws_landing--title--color);
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
	margin-bottom: 20px;
}

.desc {
	color: var(--vkws_landing--promo-desc--color);
	font-size: 15px;
	line-height: 21px;
	margin: 0;
}

.content-title {
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
}

.content-text {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 0;
	margin-top: 6px;
}

@media screen and (min-width: 1024px) {
	.root {
		flex-direction: column;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.title {
		margin-bottom: 61px;
		font-size: 36px;
	}

	.desc {
		display: none;
	}
}

@media screen and (min-width: 1440px) {
	.content-title {
		font-size: 28px;
		line-height: 32px;
	}

	.content-text {
		font-size: 20px;
		line-height: 28px;
	}

	.title {
		font-size: 54px;
		margin-bottom: 80px;
	}
}
